body {
    font-family: "IBM Plex Sans", sans-serif;
    background-color: rgba(0, 0, 0, .1);
}

h2 {
    margin: 20px auto 40px;
    font-size: 38px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 2px;
    line-height: 1.5;
    color: black;
}

details {
    width: 75%;
    min-height: 5px;
    max-width: 700px;
    padding: 20px 70px 20px 45px;
    margin: 0 auto;
    position: relative;
    font-size: 22px;
    border: 2px solid #baf5ff;
    border-radius: 15px;
    box-sizing: border-box;
    transition: all .3s;
    color: black;
    /* background-color: #def1f8; */
}

details+details {
    margin-top: 20px;
}

details[open] {
    min-height: 50px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .2);
    background-color: #baf5ff;
    color: black;
}

details p {
    color: #96999d;
    font-weight: 300;
    /* background-color: #f3faf6; */
}

summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    /* background-color: #f3faf6; */
}

summary:focus {
    outline: none;

}

summary:focus::after {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    /* box-shadow: 0 0 0 5px rebeccapurple; */
}

summary::-webkit-details-marker {
    display: none
}

.control-icon {
    /* fill: rebeccapurple; */
    transition: .3s ease;
    pointer-events: none;
    /* stroke: #F79489; */
}

.control-icon-close {
    display: none;
    color: black;
}

details[open] .control-icon-close {
    display: initial;
    transition: .3s ease;
}

details[open] .control-icon-expand {
    display: none;
}