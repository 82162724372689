@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
    }

    20%,
    60% {
        transform: scale(1.3);
    }

    40%,
    80% {
        transform: scale(1.1);
    }
}

.animate-heartbeat {
    animation: heartbeat 1s ease-in-out;
}